import React, { Children, createContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { useDebounce } from 'react-use';

import { CarouselIndicator } from './CarouselIndicator';
import { CarouselItem, carouselPageGridPadding } from './CarouselItem';
import { useMediaQuery } from '../../hooks';
import { styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';
import { Grid } from '../Grid/Grid';
import { Stack } from '../Stack/Stack';

export const CarouselGrid = styled(Grid, {
  overflowX: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  gridAutoColumns: '90%',
  '@md': {
    gridAutoColumns: '40%',
  },
  '@xl': {
    gridAutoColumns: '30%',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  variants: {
    layout: {
      default: {
        gridAutoColumns: '90%',
        '@md': {
          gridAutoColumns: '40%',
        },
        '@lg': {
          gridAutoColumns: '40%',
        },
        '@xl': {
          gridAutoColumns: '30%',
        },
      },
      peekNext: {
        gridAutoColumns: '80%',
        '@md': {
          gridAutoColumns: '90%',
        },
      },
      fullWidth: {
        gridAutoColumns: '100%',
        '@md': {
          gridAutoColumns: '100%',
        },
        '@xl': {
          gridAutoColumns: '100%',
        },
      },
    },
    hasCardPadding: {
      true: {
        paddingX: '$2',
        paddingTop: '$1',
        paddingBottom: '$4',
      },
    },
    scrollBehavior: {
      snap: {
        scrollSnapType: 'x mandatory',
      },
      native: {
        scrollSnapType: 'none',
      },
      vertical: {
        scrollSnapType: 'none',
      },
    },
    scrollMarginX: {
      gridGutter: {
        paddingX: '$6',
        '@md': {
          paddingX: '$10',
        },
        '@xl': {
          // @ts-expect-error use spacing tokens
          paddingX: carouselPageGridPadding,
        },
      },
    },
  },
});

interface CarouselContextProps extends Omit<Props, 'hasCardPadding' | 'layout'> {
  layout: CarouselVariants['layout'];
}

export const CarouselContext = createContext<CarouselContextProps>({
  scrollBehavior: 'native',
  scrollMarginX: undefined,
  showIndicator: undefined,
  layout: 'default',
});

const CarouselComponent = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  ({ as, startAt = 0, scrollTo, showIndicator, prevLabel, nextLabel, hideArrows, children, ...variantProps }, ref) => {
    const { scrollBehavior = 'native', layout = 'default', ...variants } = extractVariantProps({ ...variantProps });

    const carouselContainer = useRef<HTMLDivElement>(null);
    const carouselGrid = useRef<HTMLDivElement>(null);
    const sm = useMediaQuery('sm');
    const md = useMediaQuery('md');
    const lg = useMediaQuery('lg');
    const xl = useMediaQuery('xl');

    const [activeItems, setActiveItems] = useState([startAt]);
    const [visibleItems, setVisibleItems] = useState(activeItems.length);

    const breakpoints = useMemo(
      () =>
        [
          { query: sm, value: '@initial' },
          { query: md, value: '@md' },
          { query: lg, value: '@lg' },
          { query: xl, value: '@xl' },
        ] as const,
      [sm, md, lg, xl],
    );

    const activeScrollBehavior = useMemo(() => {
      if (!scrollBehavior) {
        return 'native';
      }

      if (typeof scrollBehavior === 'string') {
        return scrollBehavior as Props['scrollBehavior'];
      }

      let sb: Props['scrollBehavior'] = 'native';

      breakpoints.forEach(({ query, value }) => {
        const breakpointScrollBehavior = scrollBehavior?.[value];

        if (query && breakpointScrollBehavior) {
          sb = breakpointScrollBehavior;
        }
      });

      return sb;
    }, [scrollBehavior, breakpoints]);

    const activeLayout = useMemo(() => {
      if (!layout) {
        return 'default';
      }

      if (typeof layout === 'string') {
        return layout as Props['layout'];
      }

      let currentLayout: Props['layout'] = 'default';

      breakpoints.forEach(({ query, value }) => {
        const breakpointLayout = layout?.[value];

        if (query && breakpointLayout) {
          currentLayout = breakpointLayout;
        }
      });

      return currentLayout;
    }, [layout, breakpoints]);

    useImperativeHandle(ref, () => carouselContainer.current as HTMLDivElement);

    useEffect(() => {
      const carouselItems = carouselContainer?.current?.querySelectorAll('.sparky-carousel-item');
      const itemsArray = carouselItems ? Array.from(carouselItems) : [];
      let newActiveItems = activeItems;

      const observer = new IntersectionObserver(
        entries => {
          if (!itemsArray.length) {
            return;
          }

          const indexedEntries = entries.map(entry => ({
            entry,
            index: itemsArray.findIndex(item => item === entry?.target),
          }));

          const intersecting = indexedEntries.filter(({ entry }) => entry.isIntersecting).map(({ index }) => index);

          setActiveItems(items => {
            const intersectingUnique = [
              // Make sure there's no duplicates
              ...new Set([...items, ...intersecting]),
            ];
            const notIntersecting = indexedEntries
              .filter(({ entry }) => !entry.isIntersecting)
              .map(({ index }) => index);
            newActiveItems = intersectingUnique.filter(item => !notIntersecting.includes(item)).sort((a, b) => a - b);

            return newActiveItems;
          });
        },
        {
          root: carouselGrid?.current,
          threshold: 0.95,
        },
      );

      if (carouselGrid?.current && activeItems.length && carouselItems && activeItems[0] < carouselItems.length) {
        const firstItemToDisplay = itemsArray[startAt];
        const containerRect = carouselGrid.current.getBoundingClientRect();
        carouselGrid.current.scrollLeft =
          firstItemToDisplay instanceof HTMLElement
            ? firstItemToDisplay.offsetLeft -
              parseFloat(getComputedStyle(carouselGrid.current).paddingLeft) -
              containerRect.left
            : carouselGrid.current.scrollLeft;
      }

      setActiveItems(newActiveItems);

      carouselItems?.forEach(item => {
        observer.observe(item);
      });

      return () => {
        carouselItems?.forEach(item => {
          observer.unobserve(item);
        });
      };
    }, []);

    useEffect(() => {
      const scrollContainer = carouselContainer.current?.querySelector('.sparky-grid');

      if (!scrollContainer || activeScrollBehavior !== 'vertical') return;

      const scrollX = (evt: Event) => {
        const event = evt as WheelEvent;
        const hasMouseOnScrollContainer = document.elementsFromPoint(event.x, event.y).includes(scrollContainer);

        if (!hasMouseOnScrollContainer || !event.deltaY) {
          return;
        }

        const canScrollLeft = scrollContainer.scrollLeft + event.deltaY > 0;
        const canScrollRight =
          scrollContainer.scrollLeft + scrollContainer.clientWidth + event.deltaY < scrollContainer.scrollWidth;

        if (canScrollLeft && canScrollRight) {
          scrollContainer.scrollLeft += event.deltaY;
        } else if (canScrollLeft) {
          scrollContainer.scrollLeft = scrollContainer.scrollWidth;
        } else if (canScrollRight) {
          scrollContainer.scrollLeft = 0;
        }

        // Only prevent default scroll wheel behavior when user scrolls within the container
        if ((!canScrollLeft && event.deltaY < 0) || (!canScrollRight && event.deltaY > 0)) {
          return;
        }

        event.preventDefault();
      };

      scrollContainer.addEventListener('wheel', scrollX);

      return () => {
        scrollContainer?.removeEventListener('wheel', scrollX);
      };
    }, [activeScrollBehavior]);

    useDebounce(
      () => {
        if (scrollTo !== undefined) {
          onNavigationClick(scrollTo);
        }
      },
      250,
      [scrollTo],
    );

    useDebounce(
      () => {
        setVisibleItems(activeItems.length);
      },
      500,
      [activeItems],
    );

    const onNavigationClick = (index: number) => {
      if (!carouselGrid.current || isNaN(index)) {
        return;
      }

      const targetItem = carouselGrid.current.querySelectorAll('.sparky-carousel-item')?.[index];

      if (!(targetItem instanceof HTMLElement)) {
        return;
      }

      const { paddingLeft, marginLeft } = getComputedStyle(carouselGrid.current);
      const containerRect = carouselGrid.current.getBoundingClientRect();

      carouselGrid?.current?.scrollTo({
        behavior: 'smooth',
        left: targetItem?.offsetLeft
          ? targetItem.offsetLeft - containerRect.left - parseFloat(paddingLeft) - parseFloat(marginLeft)
          : 0,
      });
    };

    return (
      <CarouselContext.Provider
        value={{
          scrollBehavior: showIndicator ? 'snap' : variantProps.scrollBehavior,
          scrollMarginX: variantProps.scrollMarginX,
          layout: variantProps.layout,
        }}>
        <Stack ref={carouselContainer} gap="5">
          <CarouselGrid
            gap="6"
            flow="column"
            as={as}
            ref={carouselGrid}
            scrollBehavior={showIndicator ? 'snap' : scrollBehavior}
            layout={layout}
            {...variants}>
            {children}
          </CarouselGrid>
          {showIndicator ? (
            <CarouselIndicator
              amount={Children.toArray(children).length}
              visibleItems={visibleItems}
              activeItem={activeItems[0]}
              variant={activeLayout === 'default' && md ? 'bar' : 'dot'}
              prevLabel={prevLabel}
              nextLabel={nextLabel}
              hideArrows={hideArrows}
              onNavigationClick={onNavigationClick}
            />
          ) : null}
        </Stack>
      </CarouselContext.Provider>
    );
  },
);

type CarouselVariants = TransformStitchesToSparky<typeof CarouselGrid>;

type Props = CarouselVariants & {
  as?: Extract<keyof JSX.IntrinsicElements, 'div' | 'ul'>;
  startAt?: number;
  scrollTo?: number;
  className?: never;
} & (
    | {
        showIndicator: true;
        prevLabel?: string;
        nextLabel?: string;
        hideArrows?: boolean;
      }
    | {
        showIndicator?: false;
        prevLabel?: never;
        nextLabel?: never;
        hideArrows?: never;
      }
  );

export const Carousel = Object.assign({}, CarouselComponent, { Indicator: CarouselIndicator, Item: CarouselItem });
